import JwtService from './jwtService'
//  import axiosNoJWT from '../../../libs/axiosNoJWT'

export default function useJwt(axiosIns, jwtOverrideConfig) {
  const jwt = new JwtService(axiosIns, jwtOverrideConfig)

  return {
    jwt,
  }
}
