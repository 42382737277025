import { $authUrl } from '@microServicesConfig'

export default {
  // Endpoints
  loginEndpoint: `${$authUrl}/auth/login`,
  loginOtpEndpoint: `${$authUrl}/auth/otp/verify`,
  registerEndpoint: `${$authUrl}/auth/register`,
  refreshEndpoint: `${$authUrl}/auth/refresh-token`,
  logoutEndpoint: `${$authUrl}/auth/logout`,

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
