import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return window.$cookies.get(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return window.$cookies.get(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    window.$cookies.set(this.jwtConfig.storageTokenKeyName, value, '1D', null, null, true, 'None')
    //  window.$cookies.set(this.jwtConfig.storageTokenKeyName, value, '1D')
  }

  setRefreshToken(value) {
    window.$cookies.set(this.jwtConfig.storageRefreshTokenKeyName, value, '1D', null, null, true, 'None')
    //  window.$cookies.set(this.jwtConfig.storageRefreshTokenKeyName, value, '1D')
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  loginOtp(...args) {
    return this.axiosIns.post(this.jwtConfig.loginOtpEndpoint, ...args)
  }

  logout() {
    this.axiosIns.post(this.jwtConfig.logoutEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
      .then(() => {
        window.$cookies.remove(this.jwtConfig.storageTokenKeyName)
        window.$cookies.remove(this.jwtConfig.storageRefreshTokenKeyName)
        window.$cookies.remove('userData')
        window.sessionStorage.clear()
        window.location = '/login'
      })
      .catch(() => {
        window.$cookies.remove(this.jwtConfig.storageTokenKeyName)
        window.$cookies.remove(this.jwtConfig.storageRefreshTokenKeyName)
        window.$cookies.remove('userData')
        window.sessionStorage.clear()
        window.location = '/login'
      })
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
