import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'Dashboard',
        action: 'read',
      },
    },

    /* USER */
    {
      path: '/user-detail',
      name: 'user-detail',
      component: () => import('@/views/user/detail/Display.vue'),
      meta: {
        pageTitle: 'Kundendaten',
        breadcrumb: [
          {
            text: 'Kundendaten',
            active: false,
          },
        ],
        resource: 'Dashboard',
        action: 'read',
      },
    },
    /* OTP */
    {
      path: '/otp-auth/:otp',
      name: 'otp-auth',
      component: () => import('@/views/auth/LoginOtp.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/otp/request',
      name: 'otp-request',
      component: () => import('@/views/auth/RequestOtp.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    /* AUTH */
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login/base/:token',
      name: 'login-from-verify',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register-ocr',
      name: 'register-ocr',
      component: () => import('@/views/auth/RegisterOcr.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/verify-account/:token',
      name: 'verify-account',
      component: () => import('@/views/auth/VerifyAccount.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/verify-vin/:token',
      name: 'verify-vin',
      component: () => import('@/views/auth/VerifyVin.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    /* Partner */
    {
      path: '/partner/:partner',
      name: 'partner-selector',
      component: () => import('@/views/partner/AutomaticSelector.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/partner/:partner/:redirect',
      name: 'partner-selector-redirect',
      component: () => import('@/views/partner/AutomaticSelector.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/partner/:partner/:redirect/:token',
      name: 'partner-selector-redirect-token',
      component: () => import('@/views/partner/AutomaticSelector.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    /* non rational redirects */
    {
      path: '/mehrdahinter/salzburger-lagerhaus.at',
      name: 'redirect-salzburger-lagerhaus',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/mehrdahinter/Salzburger-Lagerhaus.at',
      name: 'redirect-salzburger-lagerhaus',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/mehrdahinter/Salzburger-Lagerhaus',
      name: 'redirect-salzburger-lagerhaus',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    {
      path: '/mehrdahinter/salzburger-lagerhaus',
      name: 'redirect-salzburger-lagerhaus',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },

    {
      path: '/genol',
      name: 'redirect-genol',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },

    {
      path: '/raiffeisen-leasing_genol',
      name: 'redirect-raiffeisen-leasing_genol',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },

    {
      path: '/raiffeisen_genol',
      name: 'redirect-raiffeisen_genol',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },

    {
      path: '/uniqa-leasing_genol',
      name: 'redirect-uniqa-leasing_genol',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },

    {
      path: '/emobil-link',
      name: 'redirect-emobil-link',
      component: () => import('@/views/partner/NonRationalRedirects.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },

    /* impressum */
    {
      path: '/impressum',
      name: 'impressum',
      component: () => import('@/views/Imprint.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    /* faq */
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/Faq.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      },
    },
    /* SYSTEM */
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // fix redirect
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })
    // If logged in => not authorized

    if (to.name === 'otp-auth') {
      return next()
    }

    return next({ name: 'misc-not-authorized' })
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
